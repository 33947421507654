import React from 'react';
import { useInView } from 'react-intersection-observer';
import {
  HomePageHero,
  HomePage123,
  HomePageTable,
  HomePageCases,
  HomePageTheory,
  HomePageAdv,
  HomePageNews,
  HomePageDevice,
  // HomePageFaq,
} from '../components';
import Layout from '../components/Layout';
import {graphql} from 'gatsby';
import Seo from 'gatsby-plugin-wpgraphql-seo';

const HomePage = (pageContext: any) => {
  const allWpNews = pageContext.data.allWpNews;
  const [ref, inView] = useInView({
    threshold: 1,
    rootMargin: '-90px 0px 0px 0px',
    initialInView: true,
  });
  const [mobileRef, mobileInView] = useInView({
    threshold: 1,
    initialInView: true,
  });
  return (
    <Layout
      headroom
      headroomState={inView}
      headroomMobileState={inView || mobileInView}
    >
      {allWpNews.edges.map( (edge : any) => {
        <Seo post={edge.node} />
      })}
      <HomePageHero headerTrigger={ref} />
    </Layout>
  );
};
export default HomePage;

export const pageQuery = graphql`
  query GET_PAGE_HOMEPAGE_NEWS {
    allWpNews {
      edges {
        node {
          seo {
            title
            metaDesc
            focuskw
            metaKeywords
            metaRobotsNoindex
            metaRobotsNofollow
            opengraphTitle
            opengraphDescription
            opengraphImage {
              altText
              sourceUrl
              srcSet
            }
            twitterTitle
            twitterDescription
            twitterImage {
              altText
              sourceUrl
              srcSet
            }
            canonical
            cornerstone
            schema {
              articleType
              pageType
              raw
            }
          }
        }
      }
    }
  }
`;